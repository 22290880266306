import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { SwiperSlide } from 'swiper/react'

import { isMobileState } from '@/recoil/layout'
import Arrows from '@/components/Block/Sliders/atoms/Arrows'
import { GridContainer } from '@/components/Grid'
import TeamCard from './TeamCard'
import cn from 'classnames'

import {
  Container,
  SwiperContent,
  ActionWrapper
} from './style'

const TeamSlider = ({ items }) => {
  const isMobile = useRecoilValue(isMobileState)
  const hasOverflow = isMobile ? true : items.length > 4
  const [swiper, setSwiper] = useState(false)

  useEffect(() => {
    if (swiper) {
      swiper.allowTouchMove = hasOverflow
    }
  }, [isMobile, swiper])

  return (
    <Container>
      <GridContainer>
        <SwiperContent onSwiper={ setSwiper } slidesPerView='auto' allowTouchMove={ hasOverflow } className={ cn({ disabled: !hasOverflow }) }>
          {
            items.map((v, k) => (
              <SwiperSlide key={ `team-${k}-${v.firstname}-${v.lastname}` }>
                <TeamCard item={ v } />
              </SwiperSlide>
            ))
          }

          { hasOverflow &&
            <ActionWrapper>
              <Arrows display='inverted' />
            </ActionWrapper>}
        </SwiperContent>
      </GridContainer>
    </Container>
  )
}

TeamSlider.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string
  }))
}

export default TeamSlider
