import React, { useRef } from 'react'
import { graphql } from 'gatsby'

import { Helmet } from 'react-helmet'
import { useSetRecoilState, useRecoilValue } from 'recoil'

import Layout from '@/layouts/Default'
import { useWatchSections } from '@/hook/index'
import { isMobileState } from '@/recoil/layout'

import Hero from '@/components/Hero'
import BlockTextScroll from '@/components/Block/TextScroll'
import BlockSlider from '@/components/Block/TextSlider'
import BlockTextImages from '@/components/Block/TextImages'
import BlockTextVideo from '@/components/Block/TextVideo'
import BlockTeamSlider from '@/components/Block/Sliders/TeamSlider'
import Verbatim from '@/components/Verbatim'
import WinerySlider from '@/components/WinerySlider'
import WinesSlider from '@/components/WinesSlider'
import SectionsNav from '@/components/SectionsNav'
import Button from '@/components/Button'

import { popupManager, popupDataManager } from '@/recoil/popup'
import { SUSTAINABILITY_POPUP_ID } from '../components/Popup/config'

import tmp from './our-story.data.json'
import { extractData } from '../utils/extractQueryData/ourStory'

const OurStoryPage = ({ data, pageContext: { pagePath, marketCode: pageMarketCode } }) => {
  if (!data) return null
  const d = extractData(data)
  const setPopup = useSetRecoilState(popupManager)
  const setPopupData = useSetRecoilState(popupDataManager)
  const isMobile = useRecoilValue(isMobileState)
  const philosophy = useRef()
  const origins = useRef()
  const vineyards = useRef()
  const people = useRef()
  const sustainability = useRef()
  const cellar = useRef()
  const wines = useRef()

  const [Section, sections] = useWatchSections(
    [philosophy, origins, vineyards, people, sustainability, cellar, wines],
    {
      offsetTop: 132
    }
  )

  const openSustainabilityPopup = (data) => {
    const image = isMobile ? data.mobileImage : data.desktopImage

    setPopupData({
      title: data.title,
      image: {
        src: image?.url,
        alt: data.alt
      },
      description: data.description,
      cta: {
        label: data.downloadLabel,
        to: data?.download?.entity?.url
      }
    })
    setPopup(SUSTAINABILITY_POPUP_ID)
  }

  const getWineryItems = _ => {
    const WineryItem = []
    for (let i = 0; i < data.drupal.nodeById.fieldAboutUsVisitUsBlock.entity.fieldVisitUsItemList.length; i++) {
      const item = data.drupal.nodeById.fieldAboutUsVisitUsBlock.entity.fieldVisitUsItemList[i].entity
      WineryItem.push({
        background: isMobile ? item.fieldVisitUsItemBackMobile.url : item.fieldVisitUsItemBackDesk.url,
        backgroundAlt: item.fieldVisitUsItemBackDesk.alt,
        href: item.fieldVisitUsItemCta.url.path,
        label: item.fieldVisitUsItemCta.title,
        image: item.fieldVisitUsItemCoverImage.url,
        imageAlt: item.fieldVisitUsItemCoverImage.alt,
        name: item.fieldVisitUsItemTitle
      })
    }

    return WineryItem
  }

  const wineryList = getWineryItems()

  const richResultMainEntity = d.team.map(({ title, imageItem, subtitle }) => {
    return {
      '@type': 'Person',
      name: title,
      image: imageItem.url,
      jobTitle: subtitle,
      worksFor: {
        '@type': 'Organization',
        name: 'Cloudy Bay'
      }
    }
  })

  const richResultSchema = {
    '@context': 'https://schema.org',
    '@type': 'Person',
    publisher: {
      '@type': 'Organization',
      name: 'Cloudy Bay',
      url: 'https://wwww.cloudybay.com/'
    },
    mainEntity: richResultMainEntity
  }

  return (
    <Layout entityBundle='about_us' path={ pagePath } pageType='Editorial' pageMarketCode={ pageMarketCode }>
      <Helmet>
        <title>{d.meta.title}</title>
        <meta name='description' content={ d.meta.description } />
        <meta property='og:title' content={ d.meta.title } />
        <meta property='og:description' content={ d.meta.description } />
        <meta property='og:url' content={ `${process.env.URL || process.env.GATSBY_DRUPAL_URL}${pagePath}` } />
        <meta property='og:site_name' content='Cloudy Bay' />
        <meta property='og:type' content='website' />
        { d.meta.image && <meta property='og:image' content={ d.meta.image } />}
        <script type='application/ld+json'>
          {JSON.stringify(richResultSchema)}
        </script>
      </Helmet>
      <Hero
        id="textcercle1"
        subtitle={ d.hero.subtitle }
        title={ d.hero.title }
        text={ d.hero.text }
        image={ d.hero.image.url }
        actionLabel={ data.drupal.nodeById.fieldHeroBlock.entity.fieldHeroCta.title }
        nbLabel={ 3 }
      />
      <SectionsNav sections={ sections } offsetTop={ 132 } />
      <Section name='our philosophy' id='our-philosophy' ref={ philosophy }>
        <BlockSlider
          id="textcercle2"
          subtitle={ d.philosophy.subtitle }
          title={ d.philosophy.title }
          text={ d.philosophy.text }
          actions={ [
            ((d.philosophy.cta) && <Button key={ 1 } to={ d.philosophy.cta.uri }>{ d.philosophy.cta.title }</Button>)
          ] }
          images={ d.philosophy.images }
        />
      </Section>
      <Section name='our origins' id='our-origins' ref={ origins }>
        <BlockTextImages
          subtitle={ d.origins.subtitle }
          title={ d.origins.title }
          text={ d.origins.text }
          actions={ [
            ((d.origins.cta) && <Button key={ 1 } to={ d.origins.cta.uri }>{ d.origins.cta.title }</Button>)
          ] }
          display='big'
          images={ d.origins.images }
          leftAlt={ d.origins.leftAlt }
          rightAlt={ d.origins.rightAlt }
        />
        <Verbatim
          duration='25s'
          text={ d.verbatim.text }
          background={ d.verbatim.backgroundDesktop.url }
          backgroundAlt={ d.verbatim.backgroundDesktop.alt }
          image={ d.verbatim.image.url }
          imageAlt={ d.verbatim.image.alt }
        />
      </Section>
      <Section name='our vineyards' id='our-vineyards' ref={ vineyards }>
        <BlockTextImages
          subtitle={ d.vineyards.subtitle }
          title={ d.vineyards.title }
          text={ d.vineyards.text }
          actions={ [
            ((d.vineyards.cta) && <Button key={ 1 } to={ d.vineyards.cta.url.path }>{ d.vineyards.cta.title }</Button>),
            ((d.vineyards.cta2) && <Button key={ 2 } to={ d.vineyards.cta2.url.path }>{ d.vineyards.cta2.title }</Button>)
          ] }
          display='inverted'
          images={ d.vineyards.images }
          leftAlt={ d.vineyards.leftAlt }
          rightAlt={ d.vineyards.rightAlt }
        />
      </Section>
      <Section name='our people' id='our-people' ref={ people }>
        <BlockTextScroll
          duration='5s'
          text={ d.scrollText }
        />
        <BlockTextVideo
          title={ d.push.title }
          subtitle={ d.push.subtitle }
          text={ d.push.text }
          video={ d.push.video.asset?.uri }
          videoThumb={ d.push.video.thumb?.url }
          thumbAlt={ d.push.video.thumb?.alt }
          videoTitle={ d.push.video.title }
          videoText={ d.push.video.text }
        />
        <BlockTeamSlider
          items={ d.team }
        />
      </Section>
      <Section name='Sustainability' id='sustainability' ref={ sustainability }>
        <BlockTextImages
          subtitle={ d.sustainability.subtitle }
          title={ d.sustainability.title }
          text={ d.sustainability.text }
          actions={ [
            ((d.sustainability.cta) &&
              <Button
                key={ 2 }
                onClick={ () => {
                  openSustainabilityPopup(d.sustainabilityPopin)
                } }
              >
                { d.sustainability.cta.title }
              </Button>)
          ] }
          display='big'
          images={ d.sustainability.images }
          leftAlt={ d.sustainability.leftAlt }
          rightAlt={ d.sustainability.rightAlt }
        />
      </Section>
      <Section name='our cellar doors' id='our-cellar-doors' ref={ cellar }>
        <WinerySlider
          id="textcercle3"
          label={ data.drupal.nodeById.fieldAboutUsVisitUsBlock.entity.fieldVisitUsSubtitle }
          items={ wineryList }
        />
      </Section>
      <Section name='discover our wines' id='discover-wines' ref={ wines }>
        <WinesSlider
          id="textcercle4"
          title={ tmp.winesLabel }
          items={ d.wines }
        />
      </Section>
    </Layout>
  )
}

export default OurStoryPage
export const query = graphql`
query OurStoryQuery {
  drupal {
    nodeById(id: "11") {
      entityId
      entityType
      entityBundle
      ... on Drupal_NodeAboutUs {
        title
        fieldHeroBlock {
          entity {
            ... on Drupal_ParagraphHeroBlock {
              fieldHeroTitle
              fieldHeroSubtitle
              fieldHeroDescription
              fieldBackgroundMobile {
                alt
                title
                url
              }
              fieldBackgroundDesktop {
                alt
                title
                url
              }
              fieldHeroCta {
                title
                uri
                url {
                  path
                }
              }
            }
          }
        }
        fieldPhilosophyBlock {

          entity {
            ... on Drupal_ParagraphStandardContentAndImageCarro {
              fieldStandardContentBlock {
                entity {
                  ... on Drupal_ParagraphStandardContentBlock {
                    fieldStandardTitle
                    fieldStandardSubtitle
                    fieldStandardDescription
                    fieldStandardCta {
                      title
                      uri
                      url {
                        path
                      }
                    }
                    fieldStandardContentCta2Link {
                      title
                      uri
                      url {
                        path
                      }
                    }
                  }
                }
              }
              fieldStandardImageCarrousel {
                entity {
                  ... on Drupal_ParagraphStandardImageCarrousel {
                    fieldCarrouselImageItem {
                      entity {
                        ... on Drupal_ParagraphCarrouselImageItem {
                          fieldImageItem {
                            alt
                            title
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        fieldOriginsBlock {
          entity {
            ... on Drupal_ParagraphStandardContentAnd2imgBlock {
              fieldStandardTextBlock {
                entity {
                  ... on Drupal_ParagraphStandardContentBlock {
                    fieldStandardTitle
                    fieldStandardSubtitle
                    fieldStandardDescription
                    fieldStandardCta {
                      title
                      uri
                      url {
                        path
                      }
                    }
                    fieldStandardContentCta2Link {
                      title
                      uri
                      url {
                        path
                      }
                    }
                  }
                }
              }
              fieldStandard2imagesBlock {
                entity {
                  ... on Drupal_ParagraphStandardTwoImagesBlock {
                    fieldLeftImage {
                      alt
                      title
                      url
                    }
                    fieldRightImage {
                      alt
                      title
                      url
                    }
                    fieldStandard2imagesStyle
                  }
                }
              }
            }
          }
        }
        fieldAboutUsVisitUsBlock {
          entity {
            ... on Drupal_ParagraphVisitUsItem {
              id
              fieldVisitUsItemCta {
                title
                url {
                  routed
                  path
                }
              }
              fieldVisitUsItemBackMobile {
                alt
              }
            }
            ... on Drupal_ParagraphVisitUsList {
              id
              fieldVisitUsSubtitle
              fieldVisitUsItemList {
                entity {
                  ... on Drupal_ParagraphVisitUsItem {
                    id
                    fieldVisitUsItemCta {
                      title
                      url {
                        routed
                        path
                      }
                    }
                    fieldVisitUsItemCoverImage {
                      alt
                      url
                    }
                    fieldVisitUsItemBackMobile {
                      url
                      alt
                    }
                    fieldVisitUsItemBackDesk {
                      url
                      alt
                    }
                    fieldVisitUsItemTitle
                  }
                }
              }
            }
          }
        }
        fieldAboutUsQuote {
          entity {
            ... on Drupal_ParagraphStandardQuoteBlock {
              fieldQuoteThumbnailImage {
                alt
                title
                url
              }
              fieldQuoteBackgroundMobile {
                alt
                title
                url
              }
              fieldQuoteBackgroundDesktop {
                alt
                title
                url
              }
              fieldQuoteText
              fieldQuoteThumbnailPosition
            }
          }
        }
        fieldAboutUsVineyardsBlock {
          entity {
            ... on Drupal_ParagraphStandardContentAnd2imgBlock {
              fieldStandardTextBlock {
                entity {
                  ... on Drupal_ParagraphStandardContentBlock {
                    fieldStandardTitle
                    fieldStandardSubtitle
                    fieldStandardDescription
                    fieldStandardCta {
                      title
                      uri
                      url {
                        path
                      }
                    }
                    fieldStandardContentCta2Link {
                      title
                      uri
                      url {
                        path
                      }
                    }
                  }
                }
              }
              fieldStandard2imagesBlock {
                entity {
                  ... on Drupal_ParagraphStandardTwoImagesBlock {
                    fieldLeftImage {
                      alt
                      title
                      url
                    }
                    fieldRightImage {
                      alt
                      title
                      url
                    }
                    fieldStandard2imagesStyle
                  }
                }
              }
            }
          }
        }
        fieldOurPeopleScrollingTitle {
          entity {
            ... on Drupal_ParagraphStandardSingleScrollingText {
              fieldScrollingText
            }
          }
        }
        fieldOurPeopleVideoBlock {
          entity {
            ... on Drupal_ParagraphStandardContentWVideoBlock {
              fieldWideVideoTextBlock {
                entity {
                  ... on Drupal_ParagraphStandardContentBlock {
                    fieldStandardTitle
                    fieldStandardSubtitle
                    fieldStandardDescription
                    fieldStandardCta {
                      title
                      uri
                      url {
                        path
                      }
                    }
                    fieldStandardContentCta2Link {
                      title
                      uri
                      url {
                        path
                      }
                    }
                  }
                }
              }
              fieldWideVideoBlock {
                entity {
                  ... on Drupal_ParagraphStandardWidescreenVideoBlock {
                    fieldWideVideoTitle
                    fieldWideVideoDescription
                    fieldWideVideoCoverImage {
                      alt
                      title
                      url
                    }
                    fieldWideVideSourceLink {
                      title
                      uri
                      url {
                        path
                      }
                    }
                  }
                }
              }
            }
          }
        }
        fieldOurPeopleTeam {
          entity {
            ... on Drupal_ParagraphStandardCarrouselFullItems {
              fieldCarrouselFullItem {
                entity {
                  ... on Drupal_ParagraphStandardFullItemCarrousel {
                    fieldFullItemCarrouselClabel
                    fieldFullItemCarrouselTitle
                    fieldFullItemCarrouselStitle
                    fieldFullItemCarrouselDesc
                    fieldFullItemCarrouselImage {
                      alt
                      title
                      url
                    }
                  }
                }
              }
            }
          }
        }
        fieldAboutSustainabilityBlock {
          entity {
            ... on Drupal_ParagraphStandardContentAnd2imgBlock {
              fieldStandardTextBlock {
                entity {
                  ... on Drupal_ParagraphStandardContentBlock {
                    fieldStandardTitle
                    fieldStandardSubtitle
                    fieldStandardDescription
                    fieldStandardCta {
                      title
                      uri
                      url {
                        path
                      }
                    }
                    fieldStandardContentCta2Link {
                      title
                      uri
                      url {
                        path
                      }
                    }
                  }
                }
              }
              fieldStandard2imagesBlock {
                entity {
                  ... on Drupal_ParagraphStandardTwoImagesBlock {
                    fieldLeftImage {
                      alt
                      title
                      url
                    }
                    fieldRightImage {
                      alt
                      title
                      url
                    }
                    fieldStandard2imagesStyle
                  }
                }
              }
            }
          }
        }
        fieldAboutUsMetaTags {
          entity {
            ... on Drupal_ParagraphMetaTags {
              fieldMetaTitle
              fieldMetaDescription
              fieldMetaOpenGraphImage {
                  url
              }
            }
          }
        }
        fieldAboutSustainabilityPopin {
          entity {
            ... on Drupal_ParagraphStandardContentWith1Image {
              fieldStandardDescription
              fieldSimpleImageDesktop {
                alt
                title
                url
              }
              fieldStandardContentDownload {
                description
                display
                entity {
                  url
                }
              }
              fieldDownloadLinkLabel
              fieldSimpleImageMobile {
                alt
                title
                url
              }
              fieldStandardContentTitle
            }
          }
        }
      }
    }
    productsQuery: taxonomyTermQuery(
      limit: 1000,
      offset: 0,
      sort: [{field: "weight", direction: ASC}],
      filter: {conditions: [{operator: EQUAL, field: "vid", value: ["wine_category"]}]}
    ) {
      entities {
        ... on Drupal_TaxonomyTermWineCategory {
          name
          fieldWineCategorySlugUrl
          fieldWineCatPrimaryVintage {
            entity {
              ... on Drupal_NodeWineProduct {
                nid
                path {
                  alias
                }
                title
                fieldProductDiscoverColorCta
                fieldWineProductBackcolor
                fieldWineProductBackillustrat {
                  url
                }
                fieldWineProductShortDesc
                fieldWineProductName
                fieldCategoryProductName
                fieldWineProductDiscoverCta {
                  title
                }
                fieldWineProdPackshotShadow {
                  url
                }
              }
            }
          }
          fieldWineCategoryFromPrice
        }
      }
    }
  }
}
`
