import * as React from 'react'
import PropTypes from 'prop-types'

import BlockText from '@/components/Block/Text'
import BlockVideo from '@/components/Block/Sliders/VideoSlider/VideoCard'

import {
  Container,
  GridContainer,
  ContentWrapper,
  VideoWrapper
} from './style'

const TextImage = ({
  title = '',
  subtitle = '',
  actions = [],
  text = '',
  video = '',
  videoThumb = '',
  videoTitle = '',
  videoText = '',
  thumbAlt = ''
}) => {
  return (
    <Container>
      <GridContainer>
        <ContentWrapper>
          <BlockText
            title={ title }
            titleTag='h2'
            subtitle={ subtitle }
            actions={ actions }
            text={ text }
          />
        </ContentWrapper>
        <VideoWrapper>
          <BlockVideo media={ {
            thumb: videoThumb,
            title: videoTitle,
            text: videoText,
            source: video,
            thumbAlt: thumbAlt,
          } }
          />
        </VideoWrapper>
      </GridContainer>
    </Container>
  )
}

TextImage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  actions: PropTypes.array,
  text: PropTypes.string,
  video: PropTypes.string,
  videoThumb: PropTypes.string,
  thumbAlt: PropTypes.string,
  videoTitle: PropTypes.string,
  videoText: PropTypes.string
}

export default TextImage
