import styled from '@emotion/styled'
import { ButtonWrapper as Button } from '@/components/Button/style'
import { ButtonLabel } from '@/components/Button/style'

import { mediaMax } from '@/styles/mixins'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  cursor: pointer;
`

export const ImageWrapper = styled.div`
  width: 100%;
  padding-top: 150%;
  position: relative;
`

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.3rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  
  ${Button}{
    padding: 1.6rem 2.5rem;
  }

  ${ButtonLabel}{
    font-size: 1rem;
  }
`

export const Identity = styled.h3`
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 2.5rem;
  line-height: 2.8rem;
  margin-bottom: .7rem;
  color: ${({ theme }) => theme.colors.brown};
`

export const Job = styled.div`
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: .1rem;
  color: ${({ theme }) => theme.colors.grey};
  margin-bottom: 1.8rem;
  flex: 1;
`

export const ActionWrapper = styled.div`
  width: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`
