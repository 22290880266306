import styled from '@emotion/styled'

import { GridContainer as GridContainerBase } from '@/components/Grid'

import { mediaMax } from '@/styles/mixins'
import {
  Container as VideoContainer,
  MediaWrapper as VideoMediaWrapper,
  ContentWrapper as VideoContentWrapper,
  Category as VideoCategory,
  Title as VideoTitle,
  Text as VideoText
} from '@/components/Block/Sliders/VideoSlider/VideoCard/style'
import {
  Subtitle as TextSubtitle,
  Title as TextTitle,
  Wysiwyg as TextWysiwyg
} from '@/components/Block/Text/style'

export const Container = styled.div`
  margin: 50px 0;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
`

export const GridContainer = styled(GridContainerBase)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`

export const ContentWrapper = styled.div`
  width: calc(100% / 2.5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5rem;

  ${mediaMax.xs} {
    width: 100%;
    margin-bottom: 2.4rem;
  }

  ${TextSubtitle} {
    margin-bottom: 3.4rem;
  }

  ${TextTitle} {
    font-size: 4.5rem;
    margin-bottom: 3rem;
  }

  ${TextWysiwyg} {
    font-size: ${({ theme }) => theme.fontSizes.paragraph.lg};
  }

`

export const VideoWrapper = styled.div`
  width: 100%;
  z-index: 3;

  ${VideoContainer} {
    position: relative;
  }

  ${VideoMediaWrapper} {
    padding-top: 52%;
  }

  ${VideoContentWrapper} {
    padding: 6rem;
    position: absolute;
    pointer-events: none;
    z-index: 2;
    height: 100%;
    width: 100%;
    top: 0;
    justify-content: flex-end;
    background-image: -moz-linear-gradient( 90deg, rgba(1, 5, 15, .4) 0%, rgba(1, 9, 23, 0) 100%);
    background-image: -webkit-linear-gradient( 90deg, rgba(1, 5, 15, .4) 0%, rgba(1, 9, 23, 0) 100%);
    background-image: -ms-linear-gradient( 90deg, rgba(1, 5, 15, .4) 0%, rgba(1, 9, 23, 0) 100%);

    ${mediaMax.xs} {
      position: static;
      background: none;
      padding: 0;
      margin-top: 2.4rem;
    }
  }

  ${VideoCategory} {
    color: ${({ theme }) => theme.colors.white};
  }

  ${VideoTitle} {
    width: calc(100% / 2.5);
    font-size: 3.6rem;
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 2rem;
    ${mediaMax.xs} {
      width: 100%;
      color: ${({ theme }) => theme.colors.black};
      font-size: 3rem;
    }
  }

  ${VideoText} {
    width: calc(100% / 2.5);
    font-size: 1.2rem;
    line-height: 2.5rem;
    letter-spacing: .1rem;
    color: ${({ theme }) => theme.colors.white};
    ${mediaMax.xs} {
      width: 100%;
      color: ${({ theme }) => theme.colors.green};
      font-size: 1.8rem;
      line-height: 1.6em;
    }
  }
`
