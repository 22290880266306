function extractImageObject (object) {
  // take a image object entity from drupal
  // and return a image object for gatsby (with a src property)
  return {
    ...object,
    src: object.url
  }
}

export const extractData = (queryData) => {
  const data = queryData.drupal
  const ourStory = data.nodeById
  const products = data.productsQuery.entities
  return {
    meta: {
      title: ourStory.fieldAboutUsMetaTags.entity.fieldMetaTitle,
      description: ourStory.fieldAboutUsMetaTags.entity.fieldMetaDescription,
      image: ourStory.fieldAboutUsMetaTags.entity.fieldMetaOpenGraphImage?.url
    },
    hero: {
      title: ourStory.fieldHeroBlock.entity.fieldHeroTitle,
      subtitle: ourStory.fieldHeroBlock.entity.fieldHeroSubtitle,
      image: ourStory.fieldHeroBlock.entity.fieldBackgroundDesktop,
      text: ourStory.fieldHeroBlock.entity.fieldHeroDescription
    },
    philosophy: {
      title: ourStory.fieldPhilosophyBlock.entity.fieldStandardContentBlock[0].entity.fieldStandardTitle,
      subtitle: ourStory.fieldPhilosophyBlock.entity.fieldStandardContentBlock[0].entity.fieldStandardSubtitle,
      text: ourStory.fieldPhilosophyBlock.entity.fieldStandardContentBlock[0].entity.fieldStandardDescription,
      actions: {
        cta: ourStory.fieldPhilosophyBlock.entity.fieldStandardContentBlock[0].entity.fieldStandardCta,
        cta2: ourStory.fieldPhilosophyBlock.entity.fieldStandardContentBlock[0].entity.fieldStandardContentCta2Link
      },
      images: ourStory.fieldPhilosophyBlock.entity.fieldStandardImageCarrousel.entity.fieldCarrouselImageItem.map(i => {
        return extractImageObject(i.entity.fieldImageItem)
      })
    },
    origins: {
      title: ourStory.fieldOriginsBlock.entity.fieldStandardTextBlock.entity.fieldStandardTitle,
      subtitle: ourStory.fieldOriginsBlock.entity.fieldStandardTextBlock.entity.fieldStandardSubtitle,
      text: ourStory.fieldOriginsBlock.entity.fieldStandardTextBlock.entity.fieldStandardDescription,
      actions: {
        cta: ourStory.fieldOriginsBlock.entity.fieldStandardTextBlock.entity.fieldStandardCta,
        cta2: ourStory.fieldOriginsBlock.entity.fieldStandardTextBlock.entity.fieldStandardContentCta2Link
      },
      images: [
        ourStory.fieldOriginsBlock.entity.fieldStandard2imagesBlock.entity.fieldLeftImage.url,
        ourStory.fieldOriginsBlock.entity.fieldStandard2imagesBlock.entity.fieldRightImage.url
      ],
      leftAlt: ourStory.fieldOriginsBlock.entity.fieldStandard2imagesBlock.entity.fieldLeftImage?.alt,
      rightAlt: ourStory.fieldOriginsBlock.entity.fieldStandard2imagesBlock.entity.fieldRightImage?.alt
    },
    verbatim: {
      text: ourStory.fieldAboutUsQuote.entity.fieldQuoteText,
      backgroundDesktop: extractImageObject(ourStory.fieldAboutUsQuote.entity.fieldQuoteBackgroundDesktop),
      image: extractImageObject(ourStory.fieldAboutUsQuote.entity.fieldQuoteThumbnailImage)
    },
    vineyards: {
      subtitle: ourStory.fieldAboutUsVineyardsBlock.entity.fieldStandardTextBlock.entity.fieldStandardSubtitle,
      title: ourStory.fieldAboutUsVineyardsBlock.entity.fieldStandardTextBlock.entity.fieldStandardTitle,
      text: ourStory.fieldAboutUsVineyardsBlock.entity.fieldStandardTextBlock.entity.fieldStandardDescription,
      cta: ourStory.fieldAboutUsVineyardsBlock.entity.fieldStandardTextBlock.entity.fieldStandardCta,
      cta2: ourStory.fieldAboutUsVineyardsBlock.entity.fieldStandardTextBlock.entity.fieldStandardContentCta2Link,
      images: [
        ourStory.fieldAboutUsVineyardsBlock.entity.fieldStandard2imagesBlock.entity.fieldLeftImage.url, 
        ourStory.fieldAboutUsVineyardsBlock.entity.fieldStandard2imagesBlock.entity.fieldRightImage.url
      ],
      leftAlt: ourStory.fieldAboutUsVineyardsBlock.entity.fieldStandard2imagesBlock.entity.fieldLeftImage?.alt,
      rightAlt: ourStory.fieldAboutUsVineyardsBlock.entity.fieldStandard2imagesBlock.entity.fieldRightImage?.alt
    },
    scrollText: ourStory.fieldOurPeopleScrollingTitle.entity.fieldScrollingText,
    push: {
      video: {
        thumb: ourStory.fieldOurPeopleVideoBlock.entity.fieldWideVideoBlock.entity.fieldWideVideoCoverImage,
        text: ourStory.fieldOurPeopleVideoBlock.entity.fieldWideVideoBlock.entity.fieldWideVideoDescription,
        title: ourStory.fieldOurPeopleVideoBlock.entity.fieldWideVideoBlock.entity.fieldWideVideoTitle,
        asset: ourStory.fieldOurPeopleVideoBlock.entity.fieldWideVideoBlock.entity.fieldWideVideSourceLink
      },
      title: ourStory.fieldOurPeopleVideoBlock.entity.fieldWideVideoTextBlock.entity.fieldStandardTitle,
      subtitle: ourStory.fieldOurPeopleVideoBlock.entity.fieldWideVideoTextBlock.entity.fieldStandardSubtitle,
      text: ourStory.fieldOurPeopleVideoBlock.entity.fieldWideVideoTextBlock.entity.fieldStandardDescription
    },
    team: ourStory.fieldOurPeopleTeam.entity.fieldCarrouselFullItem.map(i => {
      return ({
        label: i.entity.fieldFullItemCarrouselClabel,
        title: i.entity.fieldFullItemCarrouselTitle,
        subtitle: i.entity.fieldFullItemCarrouselStitle,
        description: i.entity.fieldFullItemCarrouselDesc,
        image: i.entity.fieldFullItemCarrouselImage.url,
        imageItem: i.entity.fieldFullItemCarrouselImage
      })
    }),
    sustainability: {
      ourStory: ourStory.fieldAboutSustainabilityBlock.entity,
      title: ourStory.fieldAboutSustainabilityBlock.entity.fieldStandardTextBlock.entity.fieldStandardTitle,
      subtitle: ourStory.fieldAboutSustainabilityBlock.entity.fieldStandardTextBlock.entity.fieldStandardSubtitle,
      text: ourStory.fieldAboutSustainabilityBlock.entity.fieldStandardTextBlock.entity.fieldStandardDescription,
      cta: ourStory.fieldAboutSustainabilityBlock.entity.fieldStandardTextBlock.entity.fieldStandardCta,
      cta2: ourStory.fieldAboutSustainabilityBlock.entity.fieldStandardTextBlock.entity.fieldStandardContentCta2Link,
      images: [
        ourStory.fieldAboutSustainabilityBlock.entity.fieldStandard2imagesBlock.entity.fieldLeftImage.url, 
        ourStory.fieldAboutSustainabilityBlock.entity.fieldStandard2imagesBlock.entity.fieldRightImage.url
      ],
      leftAlt: ourStory.fieldAboutSustainabilityBlock.entity.fieldStandard2imagesBlock.entity.fieldLeftImage?.alt,
      rightAlt: ourStory.fieldAboutSustainabilityBlock.entity.fieldStandard2imagesBlock.entity.fieldRightImage?.alt
    },
    sustainabilityPopin: {
      description: ourStory.fieldAboutSustainabilityPopin.entity.fieldStandardDescription,
      desktopImage: ourStory.fieldAboutSustainabilityPopin.entity.fieldSimpleImageDesktop,
      download: ourStory.fieldAboutSustainabilityPopin.entity.fieldStandardContentDownload,
      downloadLabel: ourStory.fieldAboutSustainabilityPopin.entity.fieldDownloadLinkLabel,
      mobileImage: ourStory.fieldAboutSustainabilityPopin.entity.fieldSimpleImageMobile,
      title: ourStory.fieldAboutSustainabilityPopin.entity.fieldStandardContentTitle
    },
    wines: products.map((category) => (
      {
        img: category.fieldWineCatPrimaryVintage.entity.fieldWineProdPackshotShadow.url,
        art: category.fieldWineCatPrimaryVintage.entity.fieldWineProductBackillustrat.url,
        name: category.name,
        price: category.fieldWineCategoryFromPrice,
        colorBg: category.fieldWineCatPrimaryVintage.entity.fieldWineProductBackcolor,
        colorCta: category.fieldWineCatPrimaryVintage.entity.fieldProductDiscoverColorCta,
        title: category.fieldWineCatPrimaryVintage.entity.fieldCategoryProductName,
        text: category.fieldWineCatPrimaryVintage.entity.fieldWineProductShortDesc,
        path: category.fieldWineCatPrimaryVintage.entity.path.alias
      }
    ))
  }
}
