import * as React from 'react'
import PropTypes from 'prop-types'

import resolveAssetURL from '@/utils/resolveAssetURL'

import { useSetRecoilState } from 'recoil'
import { popupManager, popupDataManager } from '@/recoil/popup'
import { TEAM_POPUP_ID } from '@/components/Popup/config'

import Button from '@/components/Button'

import {
  Container,
  ImageWrapper,
  Image,
  ContentWrapper,
  Identity,
  Job,
  ActionWrapper
} from './style'

const TeamCard = ({ item }) => {
  const setPopup = useSetRecoilState(popupManager)
  const setPopupData = useSetRecoilState(popupDataManager)

  const handleClick = () => {
    setPopupData(item)
    setPopup(TEAM_POPUP_ID)
  }

  return (
    <Container onClick={ handleClick }>
      <ImageWrapper>
        <Image loading='lazy' src={ resolveAssetURL(item.image) } alt={ item.title } />
      </ImageWrapper>
      <ContentWrapper>
        <Identity>
          { item.title }
        </Identity>
        <Job>
          { item.subtitle }
        </Job>
        <ActionWrapper>
          <Button
            onClick={ handleClick }
          >
            { item.label }
          </Button>
        </ActionWrapper>
      </ContentWrapper>
    </Container>
  )
}

TeamCard.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string
  })
}

export default TeamCard
